<template>
  <div class="app-container menus">
    <el-form
      :model="queryParams"
      ref="queryForm"
      v-hasPermi="['system:mem:list']"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
      style="width: 100%; height: 0.5%; text-align: left"
    >
      <el-form-item label="证书" prop="certificateId">
        <el-select
          v-model="queryParams.certificateId"
          placeholder="请选择证书"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in certificateDates"
            :key="dict.certificateDateId"
            :label="dict.certificateName"
            :value="dict.certificateDateId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="用户名称" prop="realName">
        <el-input
          v-model="queryParams.realName"
          placeholder="请输入用户名称"
          clearable
          size="small"
          style="width: 240px"
          @change="$forceUpdate()"
        />
      </el-form-item>
      <el-form-item label="选择学校" prop="schoolName">
        <!-- :disable-branch-nodes="true" -->
        <treeselect
          v-model="queryParams.schoolName"
          class="treeselect-main"
          :options="querySchool"
          :normalizer="normalizer"
          placeholder="选择学校"
          loadingText
          noOptionsText="无数据"
          :show-count="true"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input
          v-model="queryParams.phone"
          placeholder="请输入手机号"
          clearable
          size="small"
          style="width: 240px"
          @change="$forceUpdate()"
        />
      </el-form-item>
      <el-form-item label="是否通过" prop="pass">
        <el-select
          v-model="queryParams.pass"
          clearable
          size="small"
          style="width: 240px"
          @change="$forceUpdate()"
        >
          <el-option
            v-for="dict in userStatus"
            :key="dict.id"
            :label="dict.value"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          v-hasPermi="['system:mem:list']"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-refresh"
          size="mini"
          @click="resetQuery"
          v-hasPermi="['system:mem:list']"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:mem:exp']"
          >导出</el-button
        >
      </el-col>
    </el-row>

    <el-table
      ref="tables"
      :data="list"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <!-- <el-table-column label="用户编号" align="center" prop="id" /> -->
      <el-table-column
        label="用户名称"
        align="center"
        prop="realName"
        width="100"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="学校所属"
        align="center"
        prop="address"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="所在学校"
        align="center"
        prop="schoolName"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="手机号"
        align="center"
        prop="phone"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="学习积分"
        align="center"
        prop="studyMark"
        width="80"
      />
      <el-table-column
        label="课件积分"
        align="center"
        prop="fileMark"
        width="80"
      />
      <el-table-column
        label="教案积分"
        align="center"
        prop="teachMark"
        width="80"
      />
      <el-table-column label="总结积分" align="center" prop="mark" width="80" />
      <el-table-column
        label="考试积分"
        align="center"
        prop="examMark"
        width="80"
      />
      <el-table-column
        label="总积分"
        align="center"
        prop="markSum"
        width="80"
      />
      <el-table-column label="是否通过" align="center" prop="pass" width="80" />
      <el-table-column
        label="证书名称"
        align="center"
        prop="certificateName"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="证书编号"
        align="center"
        prop="certificateCode"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.certificateCode ? scope.row.certificateCode : "无" }}
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import request from "@/utils/request.js";
import { selectUserMarkInfo } from "@/api/user/file";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { schoolList } from "@/api/user/shcool";
import { download } from "@/utils/request";

export default {
  name: "scoreSummary",
  components: { Treeselect },
  data() {
    return {
      // 总条数
      total: 0,
      loading: true,
      ids: [],
      // 表格数据
      list: [],
      // 学校树选项
      schoolOptions: [],
      // 搜索学校树
      querySchool: [],
      // 搜索框是否显示
      showSearch: true,
      // 非多个禁用
      multiple: true,
      // 查询参数
      queryParams: {
        page: 1,
        pageSize: 10,
        certificateDateId: undefined,
      },
      userStatus: [
        { value: "是", id: "是" },
        { value: "否", id: "否" },
      ],
      certificateDates: [],
    };
  },
  async created() {
    await this.info();
    await this.getList();
    this.getSchoolOptions();
  },
  activated() {
    this.getList();
  },
  methods: {
    info() {
      let data = {
        pageSize: 100,
        pageNum: 1,
      };
      return request({
        url: "certificateDate/selectCertificateDate",
        method: "get",
        params: data,
      }).then((res) => {
        if (res.status === 200) {
          this.certificateDates = res.data.certificateDates;
          this.queryParams.certificateId =
            this.certificateDates[0].certificateDateId;
        }
      });
    },
    getList() {
      if (this.queryParams.certificateId == undefined) return;
      this.loading = true;
      selectUserMarkInfo(this.queryParams).then((res) => {
        this.list = res.data.userMarkInfos;
        this.total = res.data.pageBean.count;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.queryParams.pageSize = 10;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams.certificateId =
        this.certificateDates[0].certificateDateId;
      this.handleQuery();
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.title,
        label: node.title,
        children: node.children,
      };
    },
    // 多选框
    handleSelectionChange(val) {
      this.ids = val.map((item) => item.id);
      this.multiple = !val.length;
    },
    getSchoolOptions() {
      schoolList().then((res) => {
        if (sessionStorage.getItem("schoolIdList") == "undefined") {
          this.querySchool = this.handleTree(res.data, "id", "parentid");
        } else {
          let arr = sessionStorage.getItem("schoolIdList").split(",");
          let schoolArr = [];
          arr.forEach((item) => {
            res.data.forEach((school) => {
              if (school.id == item) schoolArr.push(school);
            });
          });
          this.querySchool = this.handleTree(schoolArr, "id", "parentid");
        }
      });
    },
    // 导出按钮
    handleExport() {
      let id = sessionStorage.getItem("userId");
      this.queryParams.adminId = id;
      download(
        "userMarkInfo/exportUserMarkInfo",
        this.queryParams,
        // `userMarkInfo_${new Date().getTime()}.xls`
        `userMarkInfo_${
          this.queryParams.schoolName == undefined
            ? new Date().getTime()
            : this.queryParams.schoolName
        }.xls`
      );
    },
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px;
}
.mb8 {
  margin: 0 -5px 8px;
}
.treeselect-main {
  width: 280px;
  margin-top: 5px;
  line-height: 30px;
}
.vue-treeselect >>> .vue-treeselect__placeholder {
  line-height: 30px;
}
.vue-treeselect >>> .vue-treeselect__control {
  height: 30px !important;
}
.vue-treeselect >>> .vue-treeselect__menu {
  overflow-x: auto !important;
}
.vue-treeselect >>> .vue-treeselect__label {
  overflow: unset;
  text-overflow: unset;
}
</style>
